import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './index.scss';
import { Container } from '@mui/material';
import AvailabilityModal from '../../components/AvailabilityModal';
import usePageBackgroundColor from '../../components/usePageBackgroundColor';
import { getCookie } from '../../utils/cookieHelper'; // Assuming you have this helper to get employee ID
import { toast } from 'react-toastify'; // Assuming you're using this for toast messages
import 'react-toastify/dist/ReactToastify.css';

const AvailabilitySchedule = () => {
  const [date, setDate] = useState(new Date());
  const [selectedDays, setSelectedDays] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(true); // To track API loading state
  const [currentMonth, setCurrentMonth] = useState(date.getMonth() + 1); // Current month
  const [currentYear, setCurrentYear] = useState(date.getFullYear()); // Current year

  const [selectedDayDetails, setSelectedDayDetails] = useState({
    morning: false,
    afternoon: false,
    night: false,
    fullDay: false,
    offDay: false,
  });

  const userId = getCookie('dewcare_user')?.userId; // Retrieve employee_id from cookie

  // Function to load the work schedule for the selected month
  const fetchWorkSchedules = async (month, year) => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://dewcare.co.uk/mobile/work-schedule?employee_id=${userId}&month=${month}&year=${year}`
      );
      const data = await response.json();
  
      if (data.success && data.work_schedules.length > 0) {
        const schedules = data.work_schedules;
        const updatedSelectedDays = {};
  
        schedules.forEach((schedule) => {
          const dayKey = new Date(schedule.date_of_work).toDateString();
          const workPeriod = schedule.work_period;
  
          updatedSelectedDays[dayKey] = {
            morning: workPeriod === 'morning',
            afternoon: workPeriod === 'afternoon',
            night: workPeriod === 'night',
            fullDay: workPeriod === 'full day',
            offDay: workPeriod === 'off day'
          };
        });
  
        setSelectedDays(updatedSelectedDays);
      } else {
        // If no schedules found, reset selectedDays without showing an error
        setSelectedDays({});
      }
    } catch (error) {
      console.error('Error fetching work schedules:', error);
      toast.error('Error loading work schedules');
    } finally {
      setLoading(false);
    }
  };
  

  // Fetch work schedules when the component mounts or when the month/year changes
  useEffect(() => {
    fetchWorkSchedules(currentMonth, currentYear);
  }, [currentMonth, currentYear]);

  // Handle when a date is clicked
  const handleDayClick = (value) => {
    setDate(value);
    const dayKey = value.toDateString();
    setSelectedDayDetails(selectedDays[dayKey] || {
      morning: false,
      afternoon: false,
      night: false,
      fullDay: false,
      offDay: false,
    });
    setModalOpen(true); // Open modal
  };

  // Save the selection
  const handleSaveSelection = async () => {
    const dayKey = date.toDateString();
    const selectedPeriods = selectedDayDetails;
    
    let workPeriod = '';
    if (selectedPeriods.fullDay) {
      workPeriod = 'full day';
    } else if (selectedPeriods.morning) {
      workPeriod = 'morning';
    } else if (selectedPeriods.afternoon) {
      workPeriod = 'afternoon';
    } else if (selectedPeriods.night) {
      workPeriod = 'night';
    } else if (selectedPeriods.offDay) {
      workPeriod = 'off day';
    }


    setSelectedDays({
      ...selectedDays,
      [dayKey]: selectedDayDetails,
    });

    const payload = {
      employee_id: userId,
      work_period: workPeriod,
      date_of_work: date.toISOString().split('T')[0], // Format date as 'YYYY-MM-DD'
    };

    try {
      const response = await fetch('https://dewcare.co.uk/mobile/employee/work-schedule', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        console.log('Work schedule submitted successfully');
        toast.success('Work schedule submitted successfully');
      } else {
        const errorData = await response.json();
        console.error('Submission failed with error:', errorData);
        toast.error('Failed to submit work schedule');
      }
    } catch (error) {
      console.error('Error submitting work schedule:', error);
      toast.error('Error submitting work schedule');
    }
    
    setModalOpen(false); // Close modal
  };

  // Show different colors on the calendar based on selected periods
  const tileContent = ({ date, view }) => {
    const dayKey = date.toDateString();
    const selectedDay = selectedDays[dayKey];

    return view === 'month' && selectedDay ? (
      <div>
        {selectedDay.morning && <span style={{ background: '#CEEFFF', padding: '3px', display: 'block', textAlign: 'center' }} />}
        {selectedDay.afternoon && <span style={{ background: '#0093DD', padding: '3px', display: 'block', textAlign: 'center' }} />}
        {selectedDay.night && <span style={{ background: '#7C7B7B', padding: '3px', display: 'block', textAlign: 'center' }} />}
        {selectedDay.fullDay && <span style={{ background: '#000000', padding: '3px', display: 'block', textAlign: 'center' }} />}
        {selectedDay.offDay && <span style={{ background: 'transparent', padding: '3px', display: 'block', textAlign: 'center', border: '1px solid #000000' }} />}
      </div>
    ) : null;
  };

  // Handle month/year change
  const handleMonthChange = ({ activeStartDate }) => {
    setCurrentMonth(activeStartDate.getMonth() + 1); // Update month
    setCurrentYear(activeStartDate.getFullYear()); // Update year
  };

  usePageBackgroundColor('#0093DD');

  return (
    <Container>
      <div className="schedule">
        <h1 className="header">Availability Schedule</h1>
        {loading ? <p style={{color: '#fff'}}>Loading work schedule...</p> : null}

        <Calendar 
          onChange={setDate} 
          value={date} 
          onClickDay={handleDayClick}
          onActiveStartDateChange={handleMonthChange} // Detect month changes
          tileContent={tileContent} 
        />
        
        {/* Modal for selecting periods */}
        <AvailabilityModal
          open={modalOpen}
          handleClose={() => setModalOpen(false)}
          selectedDayDetails={selectedDayDetails}
          setSelectedDayDetails={setSelectedDayDetails}
          date={date}
          handleSaveSelection={handleSaveSelection}
        />
      </div>

      <div className='descCon' style={{backgroundColor: '#fff', padding: '20px', marginTop: '20px', }}>
        <div style={{display: 'block', gap: '10px'}}>
          Note: 
          <p><span style={{padding: '3px', border: '1px solid #000', backgroundColor: '#CEEFFF', marginRight: '10px'}}/> Morning</p> 
          <p><span style={{padding: '3px', border: '1px solid #000', backgroundColor: '#0093DD', marginRight: '10px'}}/>Afternoon</p>
          <p><span style={{padding: '3px', border: '1px solid #000', backgroundColor: '#7C7B7B', marginRight: '10px'}}/>Night</p>
          <p><span style={{padding: '3px', border: '1px solid #000', backgroundColor: '#000000', marginRight: '10px'}}/>Full day</p>
          <p><span style={{padding: '3px', border: '1px solid #000', backgroundColor: 'transparent', marginRight: '10px'}}/>Off day</p>
          </div>
      </div>
    </Container>
  );
};

export default AvailabilitySchedule;

import React, { createContext, useState, useEffect } from 'react';
import { getCookie } from '../utils/cookieHelper';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchUserData = async () => {
    const cookieValue = getCookie('dewcare_user');
    if (cookieValue) {
      try {
        const response = await fetch(`https://dewcare.co.uk/mobile/employee/${cookieValue.userId}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setUserData(data);
      } catch (error) {
        console.error('Failed to fetch user data:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  return (
    <UserContext.Provider value={{ userData, loading, setUserData, fetchUserData }}>
      {children}
    </UserContext.Provider>
  );
};

import React, { useState, useEffect, useRef } from 'react';
import { MdLocationOn } from "react-icons/md";
import SignatureCanvas from 'react-signature-canvas';
import './index.scss';
import { Container } from '@mui/material';
import usePageBackgroundColor from '../../components/usePageBackgroundColor';
import { getCookie } from '../../utils/cookieHelper';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';

const TimeSheet = () => {
    const [formData, setFormData] = useState({
        clockIn: "00:00",
        clockOut: "00:00",
        breakStart: "",
        breakEnd: "",
        punctuality: null,
        wellMannered: null,
        attendanceToDuty: null,
    });

    const [breakTime, setBreakTime] = useState("no");
    const [jobRole, setJobRole] = useState('');
    const [clientData, setClientData] = useState({ client_name: '', address: '', client_id: '' });
    const [documentFile, setDocumentFile] = useState(null);
    const location = useLocation();
    const clientId = location.state?.clientId;
    const sigCanvas = useRef({});
    const userId = getCookie('dewcare_user')?.userId;

    const todayDate = new Date().toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit'
    });

    usePageBackgroundColor('#E1E2E4');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchEmployeeDetails = async () => {
            try {
                if (userId) {
                    const response = await fetch(`https://dewcare.co.uk/mobile/employee/${userId}`);
                    const data = await response.json();
                    if (data.success) {
                        setJobRole(data.data.position);
                    }
                }
            } catch (error) {
                console.error('Error fetching profile data:', error);
            }
        };
        fetchEmployeeDetails();
    }, [userId]);

    useEffect(() => {
        if (clientId) {
            setClientData({
                client_name: location.state.clientName,
                address: location.state.clientAddress,
                client_id: clientId
            });
        }
    }, [clientId, location.state]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleBreakChange = (event) => {
        const value = event.target.value;
        setBreakTime(value);
        if (value === "yes") {
            setFormData((prevData) => ({
                ...prevData,
                breakStart: "",
                breakEnd: ""
            }));
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        const allowedTypes = ["application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
        
        if (file && allowedTypes.includes(file.type)) {
            setDocumentFile(file);
        } else {
            toast.error("Invalid file type. Only .doc, .docx, and .pdf files are allowed.");
            setDocumentFile(null);
        }
    };

    const convertToTimestamp = (time) => {
        const [hours, minutes] = time.split(':');
        return `${parseInt(hours, 10).toString().padStart(2, '0')}${parseInt(minutes, 10).toString().padStart(2, '0')}00`;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (
            !formData.clockIn ||
            !formData.clockOut ||
            formData.punctuality === null ||
            formData.wellMannered === null ||
            formData.attendanceToDuty === null
        ) {
            toast.error("Please fill all required fields");
            return;
        }
    
        if (sigCanvas.current.isEmpty()) {
            toast.error('Please provide a signature');
            return;
        }
    
        const signatureImage = sigCanvas.current.toDataURL("image/png");
        const signatureFormData = new FormData();
        signatureFormData.append("file", signatureImage);
        signatureFormData.append("upload_preset", "default_preset");
    
        try {
            const cloudinaryResponse = await fetch("https://api.cloudinary.com/v1_1/dqkd2vnbu/image/upload", {
                method: "POST",
                body: signatureFormData,
            });
            const cloudinaryData = await cloudinaryResponse.json();
    
            if (cloudinaryData.secure_url) {
                const convertToBoolean = (value) => value === "yes";
                const payload = {
                    employee_id: userId,
                    client_id: clientData.client_id,
                    start_time: convertToTimestamp(formData.clockIn),
                    end_time: convertToTimestamp(formData.clockOut),
                    start_break_time: formData.breakStart ? convertToTimestamp(formData.breakStart) : null,
                    end_break_time: formData.breakEnd ? convertToTimestamp(formData.breakEnd) : null,
                    break_time: breakTime === "yes",
                    punctuality: convertToBoolean(formData.punctuality),
                    well_mannered: convertToBoolean(formData.wellMannered),
                    attendance_to_duty: convertToBoolean(formData.attendanceToDuty),
                    authorized_signature: cloudinaryData.secure_url,
                };
    
                const response = await fetch('https://dewcare.co.uk/mobile/employee/add-timesheet', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                });
    
                const result = await response.json();
    
                if (response.ok) {
                    console.log("Timesheet submission response:", result);
    
                    // Get the id from the response and assign it to attendanceId
                    const attendanceId = result.id;
                    if (!attendanceId) {
                        toast.error("ID is missing from the response.");
                        return;
                    }
    
                    if (documentFile) {
                        const documentFormData = new FormData();
                        // Append attendanceId as attendance_id
                        documentFormData.append("attendance_id", attendanceId);
                        documentFormData.append("document", documentFile);
    
                        const documentResponse = await fetch('https://dewcare.co.uk/mobile/timesheet/document', {
                            method: 'POST',
                            body: documentFormData,
                        });
    
                        if (documentResponse.ok) {
                            navigate('/success');
                        } else {
                            const documentError = await documentResponse.json();
                            toast.error(`Failed to upload document: ${documentError.message || 'Unknown error'}`);
                        }
                    } else {
                        navigate('/success');
                    }
                } else {
                    console.error('API Error:', result);
                    toast.error(`Failed to Submit Timesheet: ${result.message || 'Unknown error'}`);
                }
            } else {
                toast.error('Error uploading to Cloudinary');
            }
        } catch (error) {
            console.error('Error submitting timesheet:', error);
            toast.error('Error submitting timesheet');
        }
    };
    
    

    const clearSignature = () => {
        sigCanvas.current.clear();
    };
    return (
        <Container>
            <section className='timesheet-con'>
                <div className='attendance'>
                    <h4 className='header1'>New Time-Sheet</h4>
                    <h3>{clientData.client_name || "Loading..."}</h3>
                    <div className='text-container'>
                        <div className='time'>
                            <span className='desc'>Job Role</span>
                            <span className='desc'>Date</span>
                        </div>
                        <div className='time2'>
                            <span className='text'>{jobRole || "Loading..."}</span>
                            <span className='text'>{todayDate}</span>
                        </div>
                        <div className='location'>
                            <MdLocationOn style={{ color: '#0093DD', fontSize: 22 }} />
                            <span className='text'>{clientData.address || "Loading..."}</span>
                        </div>
                        <div className='plan-con'>
                            <div className='plan'>
                                <div className='plan-input'>
                                    <label>Clock-In</label>
                                    <input
                                        step="1800"
                                        type="time"
                                        name="clockIn"
                                        value={formData.clockIn}
                                        onChange={handleInputChange}
                                        className='plan-box'
                                    />
                                </div>
                                <div className='plan-input'>
                                    <label>Clock-Out</label>
                                    <input
                                        step="1800"
                                        type="time"
                                        name="clockOut"
                                        value={formData.clockOut}
                                        onChange={handleInputChange}
                                        className='plan-box'
                                    />
                                </div>
                            </div>

                            {/* Conditionally Rendered Break Times */}
                            {breakTime === "no" ? (
                                <div className="plan-input">
                                    <label>Did you take a break today?</label>
                                    <div className="radio-button-container">
                                        <label>
                                            <input
                                                type="radio"
                                                name="breakTime"
                                                value="yes"
                                                checked={breakTime === "yes"}
                                                onChange={handleBreakChange}
                                            />
                                            Yes
                                        </label>
                                        <label>
                                            <input
                                                type="radio"
                                                name="breakTime"
                                                value="no"
                                                checked={breakTime === "no"}
                                                onChange={handleBreakChange}
                                            />
                                            No
                                        </label>
                                    </div>
                                </div>
                            ) : (
                                <div className='plan'>
                                    <div className='plan-input'>
                                        <label>Break-Start</label>
                                        <input
                                            step="1800"
                                            type="time"
                                            name="breakStart"
                                            value={formData.breakStart}
                                            onChange={handleInputChange}
                                            className='plan-box'
                                        />
                                    </div>
                                    <div className='plan-input'>
                                        <label>Break-End</label>
                                        <input
                                            step="1800"
                                            type="time"
                                            name="breakEnd"
                                            value={formData.breakEnd}
                                            onChange={handleInputChange}
                                            className='plan-box'
                                        />
                                    </div>
                                </div>
                            )}
                    
                </div>
                <div style={{border: '1px solid #000', padding: '10px', marginTop: '20px'}}>
                    <label for="document" style={{marginBottom: '20px'}}>Add a document (optional)</label>
                    <input type="file" id="document" name="document" onChange={handleFileChange}></input>
                    </div>
                </div>
                
                    
                </div>
                
                <div>
                    <h4 className='header2'>Manager’s use only</h4>
                    <div className='manager-review'>
                        <div className='radio'>
                            <span className='checkbox-label'>Punctuality:</span>
                            <div className="radio-button-container">
                                <label>
                                    <input
                                        type="radio"
                                        name="punctuality"
                                        value="yes"
                                        checked={formData.punctuality === "yes"}
                                        onChange={handleInputChange}
                                    />
                                    Yes
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="punctuality"
                                        value="no"
                                        checked={formData.punctuality === "no"}
                                        onChange={handleInputChange}
                                    />
                                    No
                                </label>
                            </div>
                        </div>

                        <div className='radio'>
                            <span className='checkbox-label'>Well mannered:</span>
                            <div className="radio-button-container">
                                <label>
                                    <input
                                        type="radio"
                                        name="wellMannered"
                                        value="yes"
                                        checked={formData.wellMannered === "yes"}
                                        onChange={handleInputChange}
                                    />
                                    Yes
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="wellMannered"
                                        value="no"
                                        checked={formData.wellMannered === "no"}
                                        onChange={handleInputChange}
                                    />
                                    No
                                </label>
                            </div>
                        </div>

                        <div className='radio'>
                            <span className='checkbox-label'>Attendance to duty:</span>
                            <div className="radio-button-container">
                                <label>
                                    <input
                                        type="radio"
                                        name="attendanceToDuty"
                                        value="yes"
                                        checked={formData.attendanceToDuty === "yes"}
                                        onChange={handleInputChange}
                                    />
                                    Yes
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="attendanceToDuty"
                                        value="no"
                                        checked={formData.attendanceToDuty === "no"}
                                        onChange={handleInputChange}
                                    />
                                    No
                                </label>
                            </div>
                        </div>
                        <h4 className='checkbox-label'>Signature:</h4>
                        <div className='signature-container'>
                            
                            <SignatureCanvas
                            penColor="black"
                            canvasProps={{
                                className: 'sigCanvas'
                            }}
                            ref={sigCanvas}
                        />
                        {/* Clear button to undo signature */}
                        
                        </div>
                        <p onClick={clearSignature} style={{textAlign: 'center'}}>Undo</p>
                    </div>                    
                </div>
                <button className="btn" onClick={handleSubmit}>Submit</button>
            </section>
        </Container>
    );
};

export default TimeSheet;

import React from 'react';
import { Container } from '@mui/material';
import usePageBackgroundColor from '../../components/usePageBackgroundColor';
import './index.scss'
import notFound from '../../assets/Images/9169253.png'

const NoContractPage = () => {
  usePageBackgroundColor('#EAF7FD'); // Set page background color

  return (
    <Container>
      <section className="contract">
        <h1 className="header">Employee Contract</h1>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <p className="noContractMessage">
            No contract has been uploaded for you to sign yet.
            </p>
            <img src={notFound} alt='' style={{width: '70%', opacity: '0.7'}}/>
        </div>
      </section>
    </Container>
  );
};

export default NoContractPage;

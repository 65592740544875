import React, { useState, useEffect, useContext } from 'react';
import { AppBar, Toolbar, IconButton, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import DrawerMenu from './components/DrawerMenu';
import BottomNav from './components/BottomNav.js';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate, useLocation } from 'react-router-dom';
import Login from './pages/Login/index.js';
import Dashboard from './pages/Dashboard/index.js';
import TimeSheet from './pages/TimeSheet/index.js';
import TimeSheetHistory from './pages/TimeSheet-History/index.js';
import EmployeeContract from './pages/EmployeeContract/index.js';
import UploadContract from './pages/UploadContract/index.js';
import NoContract from './pages/NoContract/index.js'
import Profile from './pages/Profile/index.js';
import Contact from './pages/Contact/index.js';
import AvailabilitySchedule from './pages/AvailabilitySchedule/index.js';
import SubmittedSheet from './pages/SubmittedSheet/index.js';
import Success from './pages/Success/index.js';
import ClientSelection from './pages/ClientSelection/index.js';
import { getCookie, deleteCookie } from './utils/cookieHelper';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import MobileOnlyApp from './components/MobileOnlyApp.js';
import { UserContext, UserProvider } from './components/userContext.js';

function App() {
  return (
    <MobileOnlyApp>
      <UserProvider>
        <Router>
          <MainApp />
        </Router>
      </UserProvider>
    </MobileOnlyApp>
  );
}

function MainApp() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [bottomNavValue, setBottomNavValue] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  // Get user data from UserContext
  const { userData, loading } = useContext(UserContext);

  useEffect(() => {
    // Navigate to login if user data is not available or loading failed
    if (!loading && !userData) {
      navigate('/');
    }
  }, [userData, loading, navigate]);

  useEffect(() => {
    const checkCookie = () => {
      const cookieValue = getCookie('dewcare_user');
      if (cookieValue) {
        const expirationDate = new Date(cookieValue.expiration);
        const currentDate = new Date();
        if (currentDate > expirationDate) {
          deleteCookie('dewcare_user');
          navigate('/');
        }
      } else {
        navigate('/');
      }
    };
    checkCookie();
  }, [navigate]);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const isLoginPage = location.pathname === '/';

  const iconColor = location.pathname === '/availability-schedule' || location.pathname === '/contact' ? 'white' : 'inherit';

  useEffect(() => {
    switch (location.pathname) {
      case '/home':
        setBottomNavValue(0);
        break;
      case '/client-selection':
        setBottomNavValue(1);
        break;
      case '/history':
        setBottomNavValue(2);
        break;
      case '/profile':
        setBottomNavValue(3);
        break;
      default:
        setBottomNavValue(0);
    }
  }, [location.pathname]);

  return (
    <div>
      {!isLoginPage && (
        <>
          <AppBar position="static" sx={{ backgroundColor: 'transparent', boxShadow: 'none', padding: '0' }}>
            <Toolbar>
              <IconButton edge="start" aria-label="menu" onClick={handleDrawerOpen}>
                <MenuIcon sx={{ color: iconColor }} /> {/* Dynamically set the color */}
              </IconButton>
              <Box sx={{ flexGrow: 1 }} />
              <Link to="/contact">
                <IconButton>
                  <SupportAgentIcon sx={{ color: iconColor }} /> {/* Dynamically set the color */}
                </IconButton>
              </Link>
            </Toolbar>
          </AppBar>
          <DrawerMenu
            open={drawerOpen}
            onClose={handleDrawerClose}
            employeeData={userData}
            currentPath={location.pathname} // Pass the current path to DrawerMenu
          />
        </>
      )}

      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/home" element={<Dashboard employeeData={userData} />} />
        <Route path="/client-selection" element={<ClientSelection/>} />
        <Route path="/timesheet" element={<TimeSheet />} />
        <Route path="/history" element={<TimeSheetHistory />} />
        <Route path="/contract" element={<EmployeeContract />} />
        <Route path="/upload-contract" element={<UploadContract />} />
        <Route path="/no-contract" element={<NoContract />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/availability-schedule" element={<AvailabilitySchedule />} />
        <Route path="/submitted-sheet/:attendanceId" element={<SubmittedSheet />} />
        <Route path="/success" element={<Success />} />
      </Routes>

      {!isLoginPage && (
        <BottomNav
          value={bottomNavValue}
          setValue={setBottomNavValue}
          currentPath={location.pathname} // Pass the current path to BottomNav
        />
      )}
      <ToastContainer />
    </div>
  );
}

export default App;

import React, { useState, useEffect } from 'react';
import { getCookie } from '../../utils/cookieHelper';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FaArrowRight } from "react-icons/fa6";
import notFound from "../../assets/Images/9169253.png"
import './index.scss';
import usePageBackgroundColor from '../../components/usePageBackgroundColor';

const ClientSelection = () => {
    const [clients, setClients] = useState([]);
    const [loading, setLoading] = useState(true);
    const userId = getCookie('dewcare_user')?.userId;
    const navigate = useNavigate();

    useEffect(() => {
        const fetchClients = async () => {
            try {
                const response = await fetch(`https://dewcare.co.uk/mobile/employee/${userId}/clients`);
                const data = await response.json();
                if (data.success) {
                    setClients(data.clients);
                } else {
                    toast.error('Failed to fetch clients');
                }
            } catch (error) {
                toast.error('Error fetching clients');
                console.error('Error fetching clients:', error);
            } finally {
                setLoading(false);
            }
        };

        if (userId) {
            fetchClients();
        }
    }, [userId]);

    const handleClientSelect = (client) => {
        navigate(`/timesheet`, {
            state: {
                clientId: client.id,
                clientName: client.client_name,
                clientAddress: client.address
            }
        });
    };

    usePageBackgroundColor('');

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="client-selection-page" style={{padding: '10px'}}>
            <h3>Select a Client to Continue:</h3>
            {clients.length > 0 ? (
                <ul>
                    {clients.map((client) => (
                        <li key={client.id} onClick={() => handleClientSelect(client)} style={{ listStyleType: 'none', marginLeft: '-20px', marginRight: '10px' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <p>{client.client_name}</p>
                                <FaArrowRight style={{ marginTop: '20px' }} />
                            </div>
                        </li>
                    ))}
                </ul>
            ) : (
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <p>You don't have any clients assigned to you at the moment</p>
                    <img src={notFound} alt='Not found' width='70%' style={{ opacity: '0.7' }}/>
                </div>
            )}
        </div>
    );
};

export default ClientSelection;

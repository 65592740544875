import React from 'react';
import './index.scss';
import successGif from '../../assets/Images/success.gif';
import usePageBackgroundColor from '../../components/usePageBackgroundColor';
import { Link} from 'react-router-dom';

const Success = () => {
  usePageBackgroundColor('#eceeec');
  

  return (
    <div className="success">
      <img src={successGif} alt="Success" className="img" />
      <p className="warning" style={{textAlign: 'center'}}>Timesheet submitted successfully</p>
      <Link to='/history'><button className="button">
        View
      </button></Link>
    </div>
  );
};

export default Success;

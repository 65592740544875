import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const usePageBackgroundColor = (defaultColor) => {
  const location = useLocation();

  useEffect(() => {
    const setBackgroundColor = () => {
      switch (location.pathname) {
        case '/':
          document.body.style.backgroundColor = '#EAF7FD';
          break;
        case '/profile':
          document.body.style.backgroundColor = '#E1E2E4';
          break;
        case '/availability-schedule':
          document.body.style.backgroundColor = '#0093DD';
          break;
        case '/client-selection':
          document.body.style.backgroundColor = '#E1E2E4';
          break;
        case '/timesheet':
            document.body.style.backgroundColor = '#E1E2E4';
            break;
        case '/history':
            document.body.style.backgroundColor = '#E1E2E4';
            break;
        case '/contract':
            document.body.style.backgroundColor = '#EAF7FD';
            break;
        case '/submitted-sheet':
            document.body.style.backgroundColor = '#E1E2E4';
            break;
        case '/success':
            document.body.style.backgroundColor = '#eceeec';
            break;
        default:
          document.body.style.backgroundColor = defaultColor;
          break;
      }
    };

    setBackgroundColor();

    return () => {
      document.body.style.backgroundColor = defaultColor;
    };
  }, [location, defaultColor]);
};

export default usePageBackgroundColor;

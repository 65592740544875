import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './index.scss';
import usePageBackgroundColor from '../../components/usePageBackgroundColor';

const SubmittedSheet = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const attendanceId = location.state?.id;

  const [sheetDetails, setSheetDetails] = useState(null);
  const [employeeDetails, setEmployeeDetails] = useState(null);
  const [clientDetails, setClientDetails] = useState(null);

  useEffect(() => {
    if (!attendanceId) {
      // If no attendanceId is provided, navigate back or show an error
      navigate('/history');
      return;
    }

    const fetchSubmittedSheet = async () => {
      try {
        const response = await fetch(`https://dewcare.co.uk/mobile/employee/attendance/${attendanceId}`);
        const data = await response.json();
        if (data.success) {
          setSheetDetails(data.attendance_record); // Set the submitted timesheet data
          setEmployeeDetails(data.employee);
          setClientDetails(data.client);
        }
      } catch (error) {
        console.error('Error fetching submitted sheet:', error);
      }
    };

    fetchSubmittedSheet();
  }, [attendanceId, navigate]);

  usePageBackgroundColor('#EAF7FD');

  return (
    <div className="submitted-sheet-container">
      {sheetDetails && employeeDetails && clientDetails ? (
        <div className="submitted-sheet">
          <h3>Submitted Time-sheet</h3>
          <div className="sheet-details">
            <div className='col-con'>
              <div className="column">
                <div style={{marginBottom: '10px'}}>
                  <strong>Job Role:</strong> 
                  <p>{employeeDetails.position}</p>
                </div>
                <div style={{marginBottom: '10px'}}>
                  <strong>Clock-in:</strong>
                  <p>{sheetDetails.start_time}</p>
                </div>
                <div style={{marginBottom: '10px'}}>
                  <strong>Break Start:</strong>
                  <p>{sheetDetails.start_break_time}</p>
                </div>
                <div style={{marginBottom: '10px'}}>
                  <strong>Client Name:</strong> 
                  <p>{clientDetails.client_name || 'Not found'}</p>
                </div>
              </div>
              <div className="column">
                <div style={{marginBottom: '10px'}}>
                  <strong>Date:</strong> 
                  <p>{sheetDetails.date}</p>
                </div>
                <div style={{marginBottom: '10px'}}>
                  <strong>Clock-out:</strong>
                  <p>{sheetDetails.end_time}</p>
                </div>
                <div style={{marginBottom: '10px'}}>
                  <strong>Break End:</strong>
                  <p>{sheetDetails.end_break_time}</p>
                </div>
                <div style={{marginBottom: '10px'}}>
                  <strong>Location:</strong> 
                  <p>{clientDetails.location || 'Not found'}</p>
                </div>
              </div>
            </div>
            <div className="punctuality-section">
              <div className="attributes">
                <span>Punctuality:</span>
                <label>{sheetDetails.punctuality ? 'Yes' : 'No'}</label>
              </div>
              <div className="attributes">
                <span>Well-mannered:</span>
                <label>{sheetDetails.well_mannered ? 'Yes' : 'No'}</label>
              </div>
              <div className="attributes">
                <span>Attendance to duty:</span>
                <label>{sheetDetails.attendance_to_duty ? 'Yes' : 'No'}</label>
              </div>
              <div className="sign">
                <span>Signature:</span>
                <div className="sigcontainer">
                  <img src={sheetDetails.authorized_signature} alt="Authorized Signature" className="signature" />
                </div>
              </div>
            </div>
          </div>
          <p className="submission-info">
            This timesheet has been submitted and cannot be edited anymore. Contact support if you need help.
          </p>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default SubmittedSheet;

import React, { useContext } from 'react';
import { UserContext } from '../../components/userContext';
import './index.scss';
import Calendar from '../../assets/Images/Calendar.svg';
import NoResult from '../../assets/Images/9169253.png';
import { RxCountdownTimer } from "react-icons/rx";
import { HiLocationMarker } from "react-icons/hi";
import { BsFilePersonFill } from "react-icons/bs";
import { Container } from '@mui/material';
import usePageBackgroundColor from '../../components/usePageBackgroundColor';

const Dashboard = () => {
  usePageBackgroundColor('#EAF7FD');

  const { userData, loading } = useContext(UserContext);
  const lastAttendance = userData?.attendance?.last_attendance;

  return (
    <Container>
      <div className='dashboard'>
        <section className='timesheet'>
          <h4 className='header1'>Dashboard</h4>
          <div className='textContainer'>
            <div className='days'>
              <p className='number'>
                {loading ? 'Loading...' : userData?.attendance?.all_time}
              </p>
              <img src={Calendar} alt='' />
            </div>
            <p className='text'>Total days in attendance</p>
            <p className='month'>last month</p>
          </div>
        </section>

        {loading ? ( // Check loading state for the last attendance data
          <section className="container">
            <h4 className="header2">Last time-sheet recorded</h4>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <p>Loading last timesheet...</p>
            </div>
          </section>
        ) : lastAttendance ? (
          <section className="container">
            <h4 className="header2">Last time-sheet recorded</h4>
            <div className="record">
              <p className="title">
                {lastAttendance?.date_created}
              </p>
              <div className="light">
                <div className="time-info">
                  <div className='icon'>
                    <RxCountdownTimer />
                  </div>
                  <p className="text2">
                    {`${lastAttendance?.start_time} - ${lastAttendance?.end_time}`}
                  </p>
                </div>
                <div className="details">
                  <div className="detail-item">
                    <HiLocationMarker />
                    <p className="text2 break">
                      {userData?.data?.address}
                    </p>
                  </div>
                  <div className="detail-item">
                    <BsFilePersonFill />
                    <p className="text2">
                      {userData?.data?.position}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <section className="container">
            <h4 className="header2">Last time-sheet recorded</h4>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <img src={NoResult} alt='no result' width='70%' style={{ opacity: '0.7' }} />
              <p style={{ textAlign: 'center' }}>No time-sheet recorded yet</p>
            </div>
          </section>
        )}
      </div>
    </Container>
  );
};

export default Dashboard;
